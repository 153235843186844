<template>
  <v-card
    class="mb-5 pa-5"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        align="center"
      >
        <v-btn
          class="primary mx-3"
          @click.stop="$emit('garder')"
        >
          Garder les deux foyers
        </v-btn>
        <v-btn
          class="warning mx-3"
          @click.stop="$emit('annuler')"
        >
          Annuler
        </v-btn>
      </v-col>
    </v-row>
  </v-card> 
</template>

<script>
export default {
    name: 'ResolutionValidationImportBourseAuxLivresComponent',
};
</script>