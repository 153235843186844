<template>
  <div v-if="!resolution">
    <ValidationImportBourseAuxLivresComponent
      :enable-valider="Array.isArray(selected) && selected.length > 0 && selected.every(item => !hasConflit(item))"
      :enable-resoudre-conflit="Array.isArray(selected) && selected.some(item => hasConflit(item))"
      :enable-resoudre-tous-conflits="Array.isArray(items) && items.some(item => hasConflit(item))"
      @validation="({ callback }) => {
        send({ items: selected }).then(() => {
          selected = [];
          callback();
        });
      }"
      @resoudre-conflit="({ callback }) => {
        resoudreConflits({ conflits: selected });
        callback();
      }"
      @resoudre-tous-conflits="({ callback }) => {
        resoudreConflits({ conflits: items });
        callback();
      }"
      @on-filter-change="({ cdpe, cl }) => {
        filterIdCDPE = cdpe == null ? null : cdpe.id;
        filterIdCL = cl == null ? null : cl.id;
      }"
    />
    <div>
      <v-data-table
        v-model="selected"
        light
        :headers="dataTableHeaders"
        :items="items"
        item-key="id"
        show-select
        class="elevation-3"
        :loading="loading"
        loading-text="Chargement en cours"
        no-data-text="Aucun import à valider"
        sort-by="id"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageText: 'Imports par page:',
          itemsPerPageOptions: [20, 50, 100, 200],
        }"
      >
        <v-progress-linear
          v-if="loading"
          v-slot:progress
          color="blue"
        />

        <template v-slot:no-data>
          Aucun parent à valider
        </template>

        <template v-slot:header.data-table-select>
          <v-checkbox
            :input-value="selectAllValue"
            :indeterminate="selectAllIndeterminate"
            @click.stop="toggleAll"
          />
        </template>

        <template v-slot:item.date_creation="{ item }">
          <span :class="colorClassOf(item)"> {{ item.date_creation | formatDate }} </span>
        </template>

        <template v-slot:item.code="{ item }">
          <span :class="colorClassOf(item)"> {{ item.code }} </span>
        </template>

        <template v-slot:item.cl_principal="{ item }">
          <span :class="colorClassOf(item)"> {{ item.cl_principal.nom }} </span>
        </template>

        <template v-slot:item.parent="{ item }">
          <span :class="colorClassOf(item)"> {{ item.parent.prenom + ' ' + item.parent.nom }} </span>
        </template>
      </v-data-table>

      <v-progress-linear
        v-if="loading"
        :indeterminate="true"
      />
    </div>
  </div>
  <!-- Résolution de conflits -->
  <div v-else>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <ParentResolutionValidationImportBourseAuxLivresComponent
          :parent="resolutionParentA"
          :compare-to="resolutionParentB"
          @choose="mergeParents({ idToKeep: resolutionParentA.id, idToDelete: resolutionParentB.id })"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <ParentResolutionValidationImportBourseAuxLivresComponent
          :parent="resolutionParentB"
          :compare-to="resolutionParentA"
          @choose="mergeParents({ idToKeep: resolutionParentB.id, idToDelete: resolutionParentA.id })"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ResolutionValidationImportBourseAuxLivresComponent
          @annuler="cancelResolution()"
          @garder="keepParents()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ValidationImportBourseAuxLivresComponent from '../../components/workflow/ValidationImportBourseAuxLivresComponent';
import ParentResolutionValidationImportBourseAuxLivresComponent from '../../components/workflow/ParentResolutionValidationImportBourseAuxLivresComponent';
import ResolutionValidationImportBourseAuxLivresComponent from '../../components/workflow/ResolutionValidationImportBourseAuxLivresComponent';
import selectAllMixin from '../../mixins/vTableDataSelectAll';

export default {
  name: 'ValidationsNationalesContainer',
  components: {
    ValidationImportBourseAuxLivresComponent,
    ParentResolutionValidationImportBourseAuxLivresComponent,
    ResolutionValidationImportBourseAuxLivresComponent,
  },
  mixins: [selectAllMixin],
  data() {
    return {
      selected: [],
      conflits: [],
      filterIdCDPE: null,
      filterIdCL: null,
      resolution: false,
      resolutionParentA: null,
      resolutionParentB: null,
      resolutionLock: false,
      dataTableHeaders: [
        {
          text: 'Date de Création',
          sortable: true,
          value: 'date_creation',
        },
        {
          text: 'Code',
          sortable: true,
          value: 'code',
        },
        {
          text: 'CL principal',
          sortable: true,
          value: 'cl_principal',
        },
        {
          text: 'Parent',
          sortable: true,
          value: 'parent',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('validationImportBourseAuxLivres', {
      loading: 'getLoading',
      allItems: 'getImportBourseAuxLivres',
    }),
    items() {
      return this.allItems
        .filter(item => this.filterIdCDPE == null || item.cl_principal.cdpe.id == this.filterIdCDPE)
        .filter(item => this.filterIdCL == null || item.cl_principal.id == this.filterIdCL);
    },
  },
  created() {
    this.load();
  },
  methods: {
    ...mapActions('validationImportBourseAuxLivres', {
      load: 'loadImportBourseAuxLivres',
      send: 'sendValidationImportBourseAuxlivres',
      merge: 'sendMergeParentValidationImportBourseAuxlivres',
      keep: 'keepParentValidationImportBourseAuxlivres',
    }),
    mergeParents({ idToKeep, idToDelete }) {
      if (this.resolutionLock) return;
      this.resolutionLock = true;
      // en cas d'erreur on reste sur le même conflit
      this.merge({ idToKeep: idToKeep, idToDelete: idToDelete })
        .then(() => this.loadNextConflit())
        .catch(() => this.resolutionLock = false);
    },
    keepParents() {
      if (this.resolutionLock) return;
      this.resolutionLock = true;
      this.keep({ idParentA: this.resolutionParentA.id, idParentB: this.resolutionParentB.id });
      this.loadNextConflit();
    },
    cancelResolution() {
      this.resolution = false;
      this.conflits = [];
      this.selected = [];
    },
    resoudreConflits({ conflits }) {
      this.resolution = true;
      this.conflits = [...conflits];
      this.loadNextConflit();
    },
    loadNextConflit() {
      let parent = null;
      while ((parent == null || parent.homonymes.length == 0) && this.conflits.length > 0) {
        parent = this.conflits.shift();
        // si on ne trouve pas le parent dans la liste de tous les parents (allItems) c'est qu'il a été retiré 
        // par une autre résolution d'homonyme, il faut donc l'ignorer
        if(!this.allItems.some(el => el.id === parent.id)) {
          parent = null;
        }
      }
      // si a la fin du while on a pas trouvé de parent valide, alors on a terminé de traiter les résolutions
      if (parent == null || parent.homonymes.length == 0) {
        this.cancelResolution();
        return;
      }
      this.resolutionParentA = parent;
      this.resolutionParentB = parent.homonymes[0];
      // parent.homonymes[0] : on récupère l'homonyme sans le retirer de la liste
      // l'homonyme traité sera retiré de sa liste des homonymes via merge ou keep (directement dans le module vuex)
      
      // si il y avait plus d'un homonyme a traiter on garde le parent dans la liste des parents a traiter
      if (parent.homonymes.length > 1) {
        this.conflits.unshift(parent);
      }
      this.$nextTick(() => this.resolutionLock = false);
    },
    hasConflit({ homonymes }) {
      return homonymes.length > 0;
    },
    colorClassOf(item) {
      return this.hasConflit(item) ? 'red--text' : 'green--text';
    },
  },
};
</script>