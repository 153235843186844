var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.resolution)?_c('div',[_c('ValidationImportBourseAuxLivresComponent',{attrs:{"enable-valider":Array.isArray(_vm.selected) && _vm.selected.length > 0 && _vm.selected.every(function (item) { return !_vm.hasConflit(item); }),"enable-resoudre-conflit":Array.isArray(_vm.selected) && _vm.selected.some(function (item) { return _vm.hasConflit(item); }),"enable-resoudre-tous-conflits":Array.isArray(_vm.items) && _vm.items.some(function (item) { return _vm.hasConflit(item); })},on:{"validation":function (ref) {
      var callback = ref.callback;

      _vm.send({ items: _vm.selected }).then(function () {
        _vm.selected = [];
        callback();
      });
    },"resoudre-conflit":function (ref) {
      var callback = ref.callback;

      _vm.resoudreConflits({ conflits: _vm.selected });
      callback();
    },"resoudre-tous-conflits":function (ref) {
      var callback = ref.callback;

      _vm.resoudreConflits({ conflits: _vm.items });
      callback();
    },"on-filter-change":function (ref) {
      var cdpe = ref.cdpe;
      var cl = ref.cl;

      _vm.filterIdCDPE = cdpe == null ? null : cdpe.id;
      _vm.filterIdCL = cl == null ? null : cl.id;
    }}}),_c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"light":"","headers":_vm.dataTableHeaders,"items":_vm.items,"item-key":"id","show-select":"","loading":_vm.loading,"loading-text":"Chargement en cours","no-data-text":"Aucun import à valider","sort-by":"id","items-per-page":50,"footer-props":{
        itemsPerPageText: 'Imports par page:',
        itemsPerPageOptions: [20, 50, 100, 200],
      }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Aucun parent à valider ")]},proxy:true},{key:"header.data-table-select",fn:function(){return [_c('v-checkbox',{attrs:{"input-value":_vm.selectAllValue,"indeterminate":_vm.selectAllIndeterminate},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAll($event)}}})]},proxy:true},{key:"item.date_creation",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.colorClassOf(item)},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_creation))+" ")])]}},{key:"item.code",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.colorClassOf(item)},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.cl_principal",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.colorClassOf(item)},[_vm._v(" "+_vm._s(item.cl_principal.nom)+" ")])]}},{key:"item.parent",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.colorClassOf(item)},[_vm._v(" "+_vm._s(item.parent.prenom + ' ' + item.parent.nom)+" ")])]}}],null,false,1599359737),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"progress",fn:function(){return undefined},proxy:true}],null,false,63074868)}):_vm._e()],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1)],1):_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ParentResolutionValidationImportBourseAuxLivresComponent',{attrs:{"parent":_vm.resolutionParentA,"compare-to":_vm.resolutionParentB},on:{"choose":function($event){return _vm.mergeParents({ idToKeep: _vm.resolutionParentA.id, idToDelete: _vm.resolutionParentB.id })}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ParentResolutionValidationImportBourseAuxLivresComponent',{attrs:{"parent":_vm.resolutionParentB,"compare-to":_vm.resolutionParentA},on:{"choose":function($event){return _vm.mergeParents({ idToKeep: _vm.resolutionParentB.id, idToDelete: _vm.resolutionParentA.id })}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ResolutionValidationImportBourseAuxLivresComponent',{on:{"annuler":function($event){return _vm.cancelResolution()},"garder":function($event){return _vm.keepParents()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }