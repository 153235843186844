<template>
  <div>
    <v-card>
      <div class="mb-5 pa-5">
        <v-card-title
          primary-title
          class="title"
        >
          Validations import bourse aux livres
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="currentCDPE"
              :items="getFilterCDPE"
              :item-text="item => `${item.code} - ${item.nom}`"
              :disabled="forceDisabled"
              label="Département"
              return-object
              clearable
              :required="false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="currentCL"
              :items="getFilterCL"
              :item-text="item => `${item.code} - ${item.nom}`"
              :disabled="forceDisabled"
              label="Conseil Local"
              return-object
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              color="primary"
              :disabled="forceDisabled || !enableValider"
              @click.stop="showDialog = true"
            >
              Valider la sélection
            </v-btn>
            <v-btn
              color="primary"
              class="ml-3"
              :disabled="forceDisabled || !enableResoudreConflit"
              @click="resoudreConflit()"
            >
              Résoudre le conflit
            </v-btn>
            <v-btn
              color="primary"
              class="ml-3"
              :disabled="forceDisabled || !enableResoudreTousConflits"
              @click="resoudreTousConflits()"
            >
              Résoudre tous les conflits
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <ConfirmedModal
      v-if="showDialog"
      sentence="Êtes-vous sûr de vouloir valider cette sélection ?"
      btn-action="Valider"
      @close="showDialog = false"
      @confirmed="validation()"
    />
  </div>
</template>


<script>
import ConfirmedModal from '../reusableComponents/confirmedModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ValidationImportBourseAuxLivresComponent',
  components: {
    ConfirmedModal,
  },
  props: {
    enableValider: { type: Boolean, default: false },
    enableResoudreConflit: { type: Boolean, default: false },
    enableResoudreTousConflits: { type: Boolean, default: false },
  },
  data() {
    return {
      showDialog: false,
      currentCDPE: null,
      currentCL: null,
      forceDisabled: false,
    };
  },
  computed: {
    ...mapGetters('cdpe',{
      getFilterCDPE: 'getFilterDepartements',
    }),
    ...mapGetters('conseilLocal', {
      getFilterCL: 'getFilterConseilsLocaux',
    }),
  },
  watch: {
    currentCDPE(val) {
      this.currentCL = null;
      if (val) {
        this.loadFilterCL(val.id);
      } else {
        this.resetFilterCL();
      }
      this.$emit('on-filter-change', { cdpe: val, cl: null });
    },
    currentCL(val) {
      this.$emit('on-filter-change', { cdpe: this.currentCDPE, cl: val });
    },
  },
  created() {
    this.currentAnneeScolaire = this.getAnneeScolaireCourante;
  },
  methods: {
    ...mapActions('conseilLocal', {
      loadFilterCL: 'loadFilterConseilsLocaux',
      resetFilterCL: 'resetState',
    }),
    validation() {
      this.forceDisabled = true;
      this.showDialog = false;
      this.$emit('validation', { callback: () => this.forceDisabled = false });
    },
    resoudreConflit() {
      this.forceDisabled = true;
      this.$emit('resoudre-conflit', { callback: () => this.forceDisabled = false });
    },
    resoudreTousConflits() {
      this.forceDisabled = true;
      this.$emit('resoudre-tous-conflits', { callback: () => this.forceDisabled = false });
    },
  },
};
</script>