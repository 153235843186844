<template>
  <v-card
    v-if="parent"
    class="mb-5 pa-5"
    @click.stop="$emit('choose')"
  >
    <v-card-title
      primary-title
      class="title"
    >
      Parent {{ parent.code }}
    </v-card-title>
    <v-divider />
    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        <table>
          <th
            class="px-2 py-1"
            colspan="2"
          >
            <h4>Parent</h4>
          </th>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Civilité
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.parent.civilite === compareTo.parent.civilite)"> 
                {{ parent.parent.civilite }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Nom
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.parent.nom === compareTo.parent.nom)"> 
                {{ parent.parent.nom }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Prénom
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.parent.prenom === compareTo.parent.prenom)"> 
                {{ parent.parent.prenom }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              class="pa-3"
            >
              <v-divider />
            </td>
          </tr>
          <tr>
            <th
              class="px-2 py-1"
              colspan="2"
            >
              <h4>Conseil Local</h4>
            </th>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Code
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.cl_principal.code === compareTo.cl_principal.code)"> 
                {{ parent.cl_principal.code }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Nom
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.cl_principal.nom === compareTo.cl_principal.nom)"> 
                {{ parent.cl_principal.nom }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Commune
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.cl_principal.commune.id === compareTo.cl_principal.commune.id)"> 
                {{ (parent.cl_principal.commune.code_postal + ' ' + parent.cl_principal.commune.nom) }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
              class="pa-3"
            >
              <v-divider />
            </td>
          </tr>
          <tr>
            <th
              class="px-2 py-1"
              colspan="2"
            >
              <h4>Adresse</h4>
            </th>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Adresse
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.adresse.ligne1 === compareTo.adresse.ligne1)"> 
                {{ parent.adresse.ligne1 }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              <br>
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.adresse.ligne2 === compareTo.adresse.ligne2)"> 
                {{ parent.adresse.ligne2 }} 
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="px-2 py-1"
              align="right"
            >
              Commune
            </td>
            <td class="px-2 py-1">
              <span :class="colorClassIf(parent.adresse.commune.id === compareTo.adresse.commune.id)"> 
                {{ (parent.adresse.commune.code_postal + ' ' + parent.adresse.commune.nom) }} 
              </span>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-card-title
      primary-title
      class="title pt-5"
    >
      Enfants
    </v-card-title>
    <v-divider />
    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Nom
                  </th>
                  <th>
                    Prénom
                  </th>
                  <th>
                    Établissement
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="enfant in parent.enfants"
                  :key="enfant.id"
                >
                  <td>{{ enfant.nom }}</td>
                  <td>{{ enfant.prenom }}</td>
                  <td>{{ enfant.etablissement.nom }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-col>
    </v-row>
  </v-card>
  <v-card 
    v-else
    class="mb-5 pa-5"
  >
    <v-card-title
      primary-title
      class="title"
    >
      Parent
    </v-card-title>
    <v-divider />
    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        Aucun parent à afficher
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
    name: 'ParentResolutionValidationImportBourseAuxLivresComponent',
    props: {
        parent: { type: Object, default: null },
        compareTo: { type: Object, default: null },
    },
    methods: {
      colorClassIf(areSame) {
        if(areSame)
          return 'red--text font-weight-bold';
        return 'black--text';
      },
    },
};
</script>